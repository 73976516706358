import { ImgUrl } from '@/config';
import { Box, IconButton, Badge, Button, Grid, styled, alpha, InputBase } from '@mui/material';
import React from 'react'
import Link from "next/link";
import Image from "next/image"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useRouter } from 'next/router';
import LinkTopage from './linkTopage';

const Search = styled("div")(({ theme }) => ({

}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 1,
  top: "3px",
  zIndex: "9",
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: ".25rem 1rem",
  fontSize: "1rem",
  paddingLeft: `calc(1em + ${theme.spacing(1)})`,
  paddingRight: `calc(1em + ${theme.spacing(10)})`,
  width: "100%",
}));

export default function   linkTopageDesktop({
  getDataCampaign,
  isLoading,
  itemInCart,
  showNotification,
  menuIdNoti,
  handleClickNoti,
  handleImageError,
  token,
  menuId,
  handleProfileMenuOpen,
  imageSrc,
  isMobileOrTablet,
  keyword,
  handleClickSearch,
  handleChangeInput,
}: any) {

  const router = useRouter();

  const protocol = window.location.protocol;
  const host = window.location.host;

  const nextFullPath = encodeURIComponent(protocol + "//" + host + router.asPath)
  
  const handleMap = () => {
    router.push("/map" + "/?next=" + nextFullPath)
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" sx={{ py: 1 }}>
        <Grid xs={2} sm={3} md={3} lg={2}>
          <Link aria-label="open drawer" href="/" className="inline-block">
            <img
              src={
                getDataCampaign?.logo
                  ? `${ImgUrl.imageCampaign}/uploads/campaign/${getDataCampaign?.id}/logo/${getDataCampaign?.logo}`
                  : `${ImgUrl.pathImg}web/logos.webp`
              }
              alt="maaboom"
              width="200"
              style={{ maxWidth: "200px", width: "100%", paddingTop: 5 }}
            />
          </Link>
        </Grid>
        
          {router.pathname != '/map' ? (
            <Grid xs={8} sm={6} md={7} lg={8} container justifyContent="center" alignItems="center">
              <Box sx={{ width: "100%", maxWidth: "1000px",ml:"20px" }}>
                <Search
                  sx={{
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: "8px",
                    width: "100%",
                    display: "inline-block",
                    position: "relative"
                  }}
                >
                  <SearchIconWrapper>
                    <Button
                      style={{ color: "black" }}
                      onClick={handleClickSearch}
                      aria-label="Search"
                    >
                      <SearchIcon sx={{ paddingLeft: "0" }} />
                    </Button>
                  </SearchIconWrapper>

                  <StyledInputBase
                    placeholder="ค้นหาคำที่ต้องการ"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleChangeInput}
                    value={keyword}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        handleClickSearch();
                      }
                    }}
                  />
                </Search>
              </Box>
            </Grid>
          ) : (
            <Grid xs={8} sm={6} md={7} lg={9} container justifyContent="end" alignItems="end">
              <Box sx={{ textAlign: "right" }}>
                <LinkTopage handleMap={handleMap} />
              </Box>
            </Grid>
          )}
        <Grid xs={2} sm={3} md={2} lg={router.pathname != "/map" ? 2 : 1}>
          <Box sx={{ 
            display: 'flex', flexDirection: 'row', 
            gap: 2, 
            // justifyContent: 'space-between' 
            justifyContent: { xs: "space-between", sm: "end", md: "end", lg: "end" }
          }}>
            {isLoading === true && (
              <>
                <Box sx={{ display: "inline-block" }}>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    href="/cart"
                    sx={{ width: "30px", height: "30px", p: 0 }}
                  >
                    <Badge
                      badgeContent={itemInCart ?? itemInCart}
                      color="error"
                    >
                      <Image
                        src={`${ImgUrl.pathImg}web/cart.webp`}
                        alt="Profile"
                        width={23}
                        height={23}
                      />
                    </Badge>
                  </IconButton>
                </Box>
                <Box sx={{ display: "inline-block" }}>
                  <IconButton
                    size="large"
                    aria-label="notifications"
                    aria-controls={menuIdNoti}
                    aria-haspopup="true"
                    onClick={handleClickNoti}
                    sx={{ width: "30px", height: "30px", p: 0 }}
                  >
                    <Badge
                      badgeContent={showNotification}
                      color={showNotification.length === 0 ? 'default' : 'error'}
                    >
                      <Image
                        src={`${ImgUrl.pathImg}web/notification.png`}
                        alt="Notification"
                        width={25}
                        height={25}
                        onError={handleImageError}
                      />
                    </Badge>
                  </IconButton>
                </Box>
              </>
            )}

            {token === undefined ? (
              <Box
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  sx={{ p: 0, m: 0, width: "30px", height: "30px" }}
                >
                  <AccountCircleOutlinedIcon
                    sx={{ 
                      color: "#ffffff", 
                      backgroundColor: "#F9A30B", borderRadius: "50px", 
                      padding: "3px",
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              <Box
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                sx={{ display: "inline-block", pl: .25 }}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  sx={{ p: 0, m: 0, width: "30px", height: "30px" }}
                >
                  <Image
                    src={imageSrc}
                    alt="Profile"
                    width={27}
                    height={27}
                    onError={handleImageError}
                    style={{ borderRadius: "50%" }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}